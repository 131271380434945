/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
export default props =>
  props.external ? (
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        variant: "typography.link",
        color: "inherit",
        "&.active": {
          color: "primary",
        },
        "&:hover": {
          color: "purple",
        },
        ...props.style,
      }}
      onClick={e => {
        e.stopPropagation()
      }}
      {...props}
    >
      {props.children}
    </a>
  ) : (
    <Link
      {...props}
      activeClassName="active"
      sx={{
        variant: "typography.link",
        color: "inherit",
        "&.active": {
          color: "primary",
        },
        "&:hover": {
          color: "purple",
        },
        ...props.style,
      }}
    />
  )
