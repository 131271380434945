/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import theme, { rem } from "@src/theme"

const TextInput = ({
  value,
  label,
  name,
  onChange,
  error,
  helpText,
  onBlur,
  type = "text",
  placeholder,
  required,
}) => {
  return (
    <label
      htmlFor={name}
      sx={{
        display: "block",
      }}
    >
      {label && (
        <Label>
          {label}
          {required && "*"}
        </Label>
      )}
      {helpText && <HelpText>{helpText}</HelpText>}
      <Outer
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {error && (
        <p sx={{ color: "rainbowRed", mt: 1, mb: 0, fontWeight: 700 }}>
          {error}
        </p>
      )}
    </label>
  )
}

export default TextInput

const Label = styled.span`
  display: block;
  font-size: ${rem(18)};
  font-weight: 900;
  margin-bottom: ${rem(14)};
  font-family: ${theme.fonts.heading};
  text-transform: uppercase;
`
const HelpText = styled.span`
  display: block;
  margin-bottom: ${rem(14)};
  margin-top: ${rem(-14)};
`

const Outer = styled.input`
  border: 3px solid ${theme.colors.grey};
  padding: ${rem(8)};
  font-size: ${rem(18)};
  width: 100%;
  font-family: ${theme.fonts.body};
  border-radius: 0;
  color: ${theme.colors.darkPurple};
  height: ${rem(50)};
`
