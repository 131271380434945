/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import styled from "styled-components"
import theme from "@src/theme"

const QuantitySelector = ({ quantity, handleQuantityChange, limit }) => {
  const isMany = parseInt(quantity) > 1

  const handleMinusClick = () => {
    if (isMany) {
      handleQuantityChange({ quantity: parseInt(quantity) - 1 })
    }
  }

  const handlePlusClick = () => {
    if (quantity !== Number(limit)) {
      handleQuantityChange({ quantity: parseInt(quantity) + 1 })
    }
  }

  return (
    <div>
      <div
        sx={{
          border: "3px solid",
          borderColor: "grey",
          py: 1,
          px: 1,
          fontSize: 1,
          cursor: "pointer",
          width: 172,
          fontFamily: "body",
        }}
      >
        <div
          sx={{
            position: "relative",
          }}
        >
          <MinusSelector onClick={handleMinusClick} />
          <Input
            type="number"
            id="quantity"
            name="quantity"
            min="1"
            step="1"
            onChange={handleQuantityChange}
            value={quantity}
          />
          <PlusSelector
            // className="quantity-selector__plus"
            onClick={handlePlusClick}
          />
        </div>
      </div>
    </div>
  )
}

const Input = styled.input`
  text-align: center;
  outline: 0;
  border: 0;
  width: 100%;
  color: ${theme.colors.darkPurple};
`

const Selector = styled.div`
  position: absolute;
  height: 100%;
  cursor: pointer;
  background: #fff;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after,
  &::before {
    font-weight: bold;
  }
`

const MinusSelector = styled(Selector)`
  left: 0;
  top: -3px;
  &:before {
    content: "−";
    font-size: 22px;
  }
`
const PlusSelector = styled(Selector)`
  right: 0;
  top: -3px;

  &:after {
    content: "+";
    font-size: 22px;
  }
`
export default QuantitySelector
