/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { rem } from "@src/theme"

const Price = ({ price, compareAtPrice, available, styles }) => {
  let displayPrice
  if (!available) {
    displayPrice = "Sold out"
  } else if (compareAtPrice) {
    displayPrice = "$" + compareAtPrice
  } else {
    displayPrice = "$" + price
  }

  return (
    <Flex
      sx={{
        fontSize: [rem(20), rem(22)],
        px: [rem(10), 0],
        pb: [rem(10), 0],
        ...styles,
      }}
    >
      <p
        className="price"
        sx={{
          my: 0,
          lineHeight: 1.6,
          textDecoration: compareAtPrice && available ? "line-through" : null,
          opacity: compareAtPrice || !available ? 0.6 : 1,
        }}
      >
        {displayPrice}
      </p>

      {compareAtPrice && available ? (
        <p
          sx={{
            my: 0,
            ml: 1,
            lineHeight: 1.6,
            color: "red",
          }}
        >
          ${price}
        </p>
      ) : null}
    </Flex>
  )
}

export default Price
