/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { rem } from "@src/theme"

const FieldSpacer = ({ children }) => {
  if (children.length === 2) {
    return (
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: ["column", null, "row"],
          mb: [0, null, rem(30)],
        }}
      >
        {React.Children.map(children, child => (
          <div
            sx={{
              width: ["100%", null, "45%"],
              mb: [rem(30), null, 0],
            }}
          >
            {React.cloneElement(child)}
          </div>
        ))}
      </div>
    )
  } else {
    return (
      <div
        sx={{
          mb: rem(30),
        }}
      >
        {children}
      </div>
    )
  }
}

export default FieldSpacer
