/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import carrotDown from "@components/assets/icons/carrot-down.svg"
import theme, { rem } from "@src/theme"

const SelectInput = ({
  name,
  label,
  options,
  value,
  error,
  onBlur,
  onChange,
  placeholder,
  required,
}) => {
  return (
    <label
      sx={{
        display: "block",
      }}
    >
      <Label>
        {label}
        {required && "*"}
      </Label>
      <Outer
        name={name}
        value={value}
        onChange={onChange}
        onBlur={() => onBlur(name, true)}
      >
        {placeholder && (
          <option value="" selected disabled>
            {placeholder}
          </option>
        )}
        {options.map(o => (
          <option value={o.value} key={o.value}>
            {o.label}
          </option>
        ))}
      </Outer>
      {error && <p sx={{ color: "rainbowRed" }}>{error}</p>}
    </label>
  )
}

export default SelectInput

const Label = styled.span`
  display: block;
  font-size: ${rem(18)};
  margin-bottom: ${rem(14)};
  font-family: ${theme.fonts.heading};
  font-weight: 900;
  text-transform: uppercase;
`

const Outer = styled.select`
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: url(${carrotDown}) no-repeat right #ddd;
  background-position-x: 97%;
  background-color: transparent;
  border: 3px solid;
  border-color: ${theme.colors.grey};
  padding: ${rem(8)};
  font-size: ${rem(18)};
  height: ${rem(50)};
  width: 100%;
  font-family: ${theme.fonts.body};
  border-radius: 0;
  color: ${theme.colors.darkPurple};
`
