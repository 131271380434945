import { init } from "@rematch/core"
import createRematchPersist from "@rematch/persist"
import models from "./models"
import shopModels from "@shop/store/models"


const persistPlugin = createRematchPersist({
  version: 1,
  whitelist: ["cart"],
})
export default init({
  models: {...models, ...shopModels},
  plugins: [persistPlugin],
})
