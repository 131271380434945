/** @jsx jsx */
import { jsx } from "theme-ui"

export default ({
  children,
  bg = "darkPurple",
  color = "white",
  onClick,
  styles,
  type,
  disabled,
}) => (
  <button
    sx={{
      variant: "buttons.primary",
      color,
      bg,
      "&:hover, &:active": {
        bg: "purple",
      },
      ...styles,
    }}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {children}
  </button>
)
