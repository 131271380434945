/** @jsx jsx */
import { jsx } from "theme-ui"

import FlagAromantic from "@components/assets/svg/flag-aromantic.svg"
import FlagAsexual from "@components/assets/svg/flag-asexual.svg"
import FlagBisexual from "@components/assets/svg/flag-bisexual.svg"
import FlagIntersex from "@components/assets/svg/flag-intersex.svg"
import FlagLesbian from "@components/assets/svg/flag-lesbian.svg"
import FlagNonbinary from "@components/assets/svg/flag-nonbinary.svg"
import FlagPansexual from "@components/assets/svg/flag-pansexual.svg"
import FlagProgress from "@components/assets/svg/flag-progress.svg"
import FlagRainbow from "@components/assets/svg/flag-rainbow.svg"
import FlagRainbowPoc from "@components/assets/svg/flag-rainbow-poc.svg"
import FlagTrans from "@components/assets/svg/flag-trans.svg"
import FlagGayMLM from "@components/assets/svg/flag-gay-mlm.svg"
import FlagProgressIntersex from "@components/assets/svg/flag-progress-intersex.svg"

const icons = {
  "flag-aromantic": FlagAromantic,
  "flag-asexual": FlagAsexual,
  "flag-bisexual": FlagBisexual,
  "flag-intersex": FlagIntersex,
  "flag-lesbian": FlagLesbian,
  "flag-nonbinary": FlagNonbinary,
  "flag-pansexual": FlagPansexual,
  "flag-progress": FlagProgress,
  "flag-rainbow": FlagRainbow,
  "flag-rainbow-poc": FlagRainbowPoc,
  "flag-trans": FlagTrans,
  "flag-gay-mlm": FlagGayMLM,
  "flag-progress-intersex": FlagProgressIntersex,
}

const Icon = ({ name, styles }) => {
  const IconSVG = icons[name]

  if (!IconSVG) {
    throw new Error(`Icon "${name}" doesn't exist`)
  }

  return (
    <i
      aria-hidden="true"
      sx={{
        ...styles,
        svg: {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <IconSVG />
    </i>
  )
}

export default Icon
