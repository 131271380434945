import Client from "shopify-buy"
import { isBrowser } from "@helpers"
export const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  domain: `${process.env.SHOPIFY_DOMAIN}.myshopify.com`,
})

let checkoutPromise

if (isBrowser) {
  checkoutPromise = client.checkout.create()
}


export const getNewCheckoutPromise = async () => {
  const checkout = client.checkout.create()
  console.log('Fetching new Checkout Object', checkout)
  return checkout
}

export { checkoutPromise }
