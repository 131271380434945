/** @jsx jsx */
import { jsx } from "theme-ui"
import { Themed } from "@theme-ui/mdx"
import { useState } from "react"
import styled from "styled-components"
import { rem } from "@src/theme"
import axios from "axios"
import Button from "@components/Button"
import { Formik } from "formik"
import * as Yup from "yup"
import get from "lodash/get"
import Logo from "@components/assets/svg/logo.svg"
import Facebook from "@components/assets/svg/facebook.svg"
import Instagram from "@components/assets/svg/instagram.svg"
import TikTok from "@components/assets/svg/tiktok.svg"
import LinkedIn from "@components/assets/svg/linkedin.svg"
import Youtube from "@components/assets/svg/youtube.svg"
import Flags from "@components/assets/svg/flags.svg"
import Link from "@components/Link"
import Checkbox from "@components/form/Checkbox"
import Spacer from "@components/Spacers"
import useMetaPixel from "@src/utils/useMetaPixel"

const Footer = ({ ...props }) => {
  const [success, setSuccess] = useState(null)
  const { pushEvent } = useMetaPixel()

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .required("This is required"),
  })

  const initialFormState = {
    email: "",
    interests: [],
  }

  const getError = (name, errors, touched) =>
    get(touched, name) && get(errors, name) ? get(errors, name) : ""

  return (
    <div
      sx={{
        width: ["90%", null, "100%"],
        maxWidth: rem(1800),
        mx: "auto",
      }}
    >
      <footer
        sx={{
          width: "100%",
          px: [0, null, rem(80)],
        }}
      >
        <Spacer.Large />
        <div
          sx={{
            display: "flex",
            flexDirection: ["column", null, null, "row"],
            mb: rem(80),
          }}
        >
          <div>
            <Logo
              sx={{
                width: rem(135),
                height: rem(45),
              }}
            />
          </div>

          <div
            sx={{
              flex: 2,
              px: [0, null, null, rem(76)],
              py: [rem(35), null, null, 0],
              height: ["auto", null, null, rem(133)],
            }}
          >
            <Formik
              validationSchema={validationSchema}
              initialValues={initialFormState}
              onSubmit={(values, actions) => {
                if (!values.interests.length) {
                  values.interests = ["General updates"]
                }

                const mailchimpLambdaOptions = {
                  url: "/.netlify/functions/subscribe",
                  method: "post",
                  data: {
                    email_address: values.email,
                    tags: values.interests,
                  },
                }

                pushEvent({ eventCode: "CompleteRegistration" })

                axios(mailchimpLambdaOptions)
                  .then(response => {
                    setSuccess(true)
                    actions.resetForm(initialFormState)
                    window.setTimeout(() => {
                      setSuccess(false)
                    }, 5000)
                  })
                  .catch(err => {
                    console.log(err)
                  })
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                values,
                errors,
                touched,
              }) => (
                <form
                  sx={{
                    height: ["auto", null, null, rem(52)],
                  }}
                  id="subscribe-form"
                >
                  <div>
                    <Themed.h4
                      is="label"
                      sx={{
                        display: "block",
                        mb: rem(16),
                      }}
                    >
                      Stay in the loop
                    </Themed.h4>

                    <SubscribeInput>
                      <input
                        type="email"
                        sx={{
                          border: "3px solid",
                          borderColor: "grey",
                          borderRight: "none",
                          py: 1,
                          px: 1,
                          pl: 2,
                          fontSize: rem(18),
                          width: "100%",
                          fontFamily: "body",
                          borderRadius: 0,
                          color: "darkPurple",
                          height: rem(50),
                        }}
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Your email"
                      />
                      <div
                        sx={{
                          height: "100%",
                          button: {
                            lineHeight: rem(16),
                          },
                        }}
                      >
                        <Button onClick={handleSubmit}>Subscribe</Button>
                      </div>
                    </SubscribeInput>
                    {getError("email", errors, touched) && (
                      <div
                        sx={{
                          mt: rem(10),
                          height: rem(35),
                        }}
                      >
                        <p sx={{ color: "red", maxWidth: rem(500) }}>
                          {getError("email", errors, touched)}
                        </p>
                      </div>
                    )}
                    {success && (
                      <p sx={{ color: "green", maxWidth: rem(500) }}>
                        You're now subscribed!
                      </p>
                    )}

                    <CheckboxListWrapper
                      sx={{
                        mt:
                          !getError("email", errors, touched) || !success
                            ? rem(20)
                            : 0,
                        display: "flex",
                        listStyle: "none",
                        flexDirection: ["column", null, "row"],
                      }}
                    >
                      <li
                        sx={{
                          mr: rem(22),
                        }}
                      >
                        <Checkbox
                          name="interests"
                          value="General updates"
                          setFieldValue={setFieldValue}
                          fieldValue={values.interests}
                        />
                      </li>

                      <li
                        sx={{
                          mr: rem(22),
                        }}
                      >
                        <Checkbox
                          name="interests"
                          value="Youth"
                          setFieldValue={setFieldValue}
                          fieldValue={values.interests}
                        />
                      </li>

                      <li
                        sx={{
                          mr: rem(22),
                        }}
                      >
                        <Checkbox
                          name="interests"
                          value="Schools"
                          setFieldValue={setFieldValue}
                          fieldValue={values.interests}
                        />
                      </li>
                      <li
                        sx={{
                          mr: rem(22),
                        }}
                      >
                        <Checkbox
                          name="interests"
                          value="Workplaces"
                          setFieldValue={setFieldValue}
                          fieldValue={values.interests}
                        />
                      </li>
                    </CheckboxListWrapper>
                  </div>
                </form>
              )}
            </Formik>
          </div>

          <div
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
            }}
          >
            <ul sx={{ display: "flex", listStyle: "none" }}>
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: rem(22),
                }}
              >
                <a
                  href="https://www.facebook.com/minus18youth/"
                  className="unstyled-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook />
                </a>
              </li>
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: rem(22),
                }}
              >
                <a
                  href="https://www.instagram.com/minus18youth/?hl=en"
                  className="unstyled-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram />
                </a>
              </li>
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: rem(22),
                }}
              >
                <a
                  href="https://www.tiktok.com/@minus18youth"
                  className="unstyled-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TikTok />
                </a>
              </li>
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: rem(22),
                }}
              >
                <a
                  href="https://www.linkedin.com/company/2407624"
                  className="unstyled-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn />
                </a>
              </li>
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://www.youtube.com/channel/UCWFCrMMlzmXcZj7yJkiM08g"
                  className="unstyled-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Youtube />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          sx={{
            display: "flex",
            flexDirection: ["column", null, null, "row"],
          }}
        >
          <div
            sx={{
              width: rem(135),
            }}
          >
            <Flags width="100%" />
          </div>

          <div
            sx={{
              flex: 2,
              px: [0, null, null, rem(76)],
              py: [rem(35), null, null, 0],
            }}
          >
            <p
              sx={{
                my: 0,
              }}
            >
              Minus18 is located on the land of the Yalakut Weelam Clan of the
              Boon Wurrung peoples, Australia’s First People and Traditional
              Custodians. We acknowledge their continued connection and
              contribution to land, water and community, and pay our respects to
              Elders past, present and emerging. Sovereignty was never ceded;
              this always was, always will be, Aboriginal land.
            </p>
          </div>

          <div
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
            }}
          >
            <ul sx={{ listStyle: "none", margin: 0 }}>
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: rem(10),
                }}
              >
                <Link to="/contact">Contact</Link>
              </li>
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: rem(10),
                }}
              >
                <Link to="/news?tag=reports">Annual report</Link>
              </li>

              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: rem(10),
                }}
              >
                <Link to="/privacy">Privacy</Link>
              </li>

              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: rem(10),
                }}
              >
                <Link to="/news">News and media</Link>
              </li>
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: rem(10),
                }}
              >
                <Link to="/news/access-and-inclusion">
                  Access and inclusion
                </Link>
              </li>
              <li
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: rem(10),
                }}
              >
                <Link to="/governance">Governance</Link>
              </li>
            </ul>
          </div>
        </div>
        <Spacer.Large />
      </footer>
    </div>
  )
}

export default Footer

const SubscribeInput = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(50)};
  label {
    height: 100%;
    input {
      height: 100%;
      border-right: none;
    }
  }
  button {
    height: 100%;
  }
`

const CheckboxListWrapper = styled.ul`
  .checkmark {
    transform: translateY(1px);
  }
`
