/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment, useEffect } from "react"
import styled from "styled-components"
// import FocusLock from "react-focus-lock"
import Cross from "@components/assets/svg/cross.svg"
const Modal = ({ children, onClose, active, noPadding }) => {
  useEffect(() => {
    const body = document.querySelector("body")

    if (active === true) {
      body.className += "modal-opened"
    } else {
      body.className = ""
    }
  }, [active])

  useEffect(() => {
    document.onkeydown = e => {
      if (e.key === "Escape" || e.keyCode === 27) {
        onClose()
      }
    }

    return () => {
      document.onkeydown = () => {}
    }
  }, [])
  return (
    <Fragment>
      {active && (
        <Fragment>
          <Overlay onClick={onClose}></Overlay>
          <ModalOuter>
            <Content>
              <button
                sx={{
                  color: "darkPurple",
                  position: "absolute",
                  right: 0,
                  right: "20px",
                  top: "25px",
                  cursor: "pointer",
                }}
              >
                <Cross onClick={onClose} />
              </button>
              <div
                sx={{
                  p: noPadding ? 0 : [4, null, 6],
                }}
              >
                {children}
              </div>
            </Content>
          </ModalOuter>
        </Fragment>
      )}
    </Fragment>
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;

  background: rgba(0, 0, 0, 0.5);
`
const Content = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  background: white;
`
const ModalOuter = styled.div`
  /* This way it could be display flex or grid or whatever also. */
  display: block;

  /* Probably need media queries here */
  width: 1000px;
  max-width: 90%;

  height: 800px;
  max-height: 100%;

  position: fixed;

  z-index: 100;

  left: 50%;
  top: 50%;

  /* Use this for centering if unknown width/height */
  transform: translate(-50%, -50%);

  background: white;
  box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
`

export default Modal
