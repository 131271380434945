export const donationModal = {
    state: {
        open: false,
    },
    reducers: {
        toggleDonationModal(state, payload) {
            return {
                ...state,
                open: payload,
            }
        },
    },
}
