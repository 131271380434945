/** @jsx jsx */
import { jsx, Styled, Flex } from "theme-ui"
import { Themed } from "@theme-ui/mdx"
import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { connect } from "react-redux"

// Theme
import theme, { rem, articleStyles } from "@src/theme"

// Components
import VariantSelector, { ButtonOption } from "@shop/components/VariantSelector"
import QuantitySelector from "@shop/components/QuantitySelector"
import Price from "@shop/components/Price"
import { Grid, Image } from "@theme-ui/components"
import Button from "@components/Button"
import ArrowRight from "@components/assets/svg/arrow-right.svg"
import Carousel from "@components/Carousel"

// Utils
import { client } from "@shop/helpers"
import { percentageOff } from "@helpers/utils/percentageOff"
import useMetaPixel from "@src/utils/useMetaPixel"

// CSS

const InfoDrop = ({ name, children }) => {
  const expanderInnerEl = useRef(null)
  const headEl = useRef(null)

  const [expanded, setExpanded] = useState(true)
  const [targetHeight, setTargetHeight] = useState(1000)

  const handleHeadClick = () => {
    setExpanded(!expanded)
    setTargetHeight(
      expanded
        ? 0
        : expanderInnerEl.current.clientHeight + headEl.current.clientHeight
    )
  }

  return (
    <div>
      <Head ref={headEl} onClick={handleHeadClick}>
        <Themed.h5 sx={{ mb: 1, fontSize: 1, mt: 2 }}>{name}</Themed.h5>
        <Themed.h5 sx={{ mb: 1, fontSize: 1, mt: 2 }}>
          {expanded ? "−" : "+"}
        </Themed.h5>
      </Head>
      <Expander targetHeight={targetHeight}>
        <InfoContent
          ref={expanderInnerEl}
          sx={{
            ...articleStyles,
            pb: 0,
          }}
        >
          {children}
        </InfoContent>
      </Expander>
    </div>
  )
}

const Expander = styled.div`
  max-height: 0;
  transition: max-height 300ms ease-in;
  overflow: hidden;
  ${props =>
    props.targetHeight &&
    `
    max-height: ${props.targetHeight}px;
  `}
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
`

const InfoContent = styled.div`
  font-size: ${rem(16)};
`

const ProductModal = ({
  modalProductFromStore,
  productFromProps,
  addVariantToCart,
  closeProductModal,
  cartAdding,
  ...props
}) => {
  const sliderRef = useRef(null)

  const { pushEvent } = useMetaPixel()

  const qtyItems = setAddQty(productFromProps?.orderLimit?.value || null)

  const modalProduct = productFromProps || modalProductFromStore
  const [quantity, setQuantity] = useState(1)
  const [customQuantity, setCustomQuantity] = useState(1)

  const [variant, setVariant] = useState(
    client.product.helpers.variantForOptions(
      modalProduct,
      modalProduct.variants[0].selectedOptions.reduce(
        (obj, item) => Object.assign(obj, { [item.name]: item.value }),
        {}
      )
    ) || variant
  )
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)

  const [selectedOptions, setSelectedOptions] = useState([
    ...variant.selectedOptions,
  ])

  useEffect(() => {
    checkAvailability(modalProduct.shopifyId)
  }, [variant])

  const [available, setAvailable] = useState(variant.availableForSale)
  const hasVariants = modalProduct.variants.length > 1

  const checkAvailability = productId => {
    client.product.fetch(productId).then(product => {
      // this checks the currently selected variant for availability
      const result = product.variants.filter(v => v.id === variant.shopifyId)
      setAvailable(result[0].available)
    })
  }

  const handleCustomQuantityChange = ({ quantity, target }) => {
    setCustomQuantity(quantity ? quantity : target.value)
  }

  const handleOptionChange = ({ name, value }) => {
    function pushToArray(arr, obj) {
      const index = arr.findIndex(e => e.name === obj.name)

      if (index === -1) {
        arr.push(obj)
      } else {
        arr[index] = obj
      }
    }

    const updatedSelectedOptions = [...selectedOptions]

    pushToArray(updatedSelectedOptions, { name, value })

    setSelectedOptions(updatedSelectedOptions)
    const variantResult = client.product.helpers.variantForOptions(
      modalProduct,
      updatedSelectedOptions.reduce(
        (obj, item) => Object.assign(obj, { [item.name]: item.value }),
        {}
      )
    )

    setVariant(variantResult)

    // Move slider to image
    const image = variantResult.image
    if (image) {
      const imageIndex = modalProduct.media.findIndex(
        media => media.image.originalSrc === image.originalSrc
      )
      if (imageIndex >= 0) {
        sliderRef.current.slickGoTo(imageIndex)
      }
    }
  }

  function setAddQty(integer) {
    let numbers = [1, 5, 10, 20, 50]
    if (!integer) return numbers
    numbers = numbers.filter(num => num <= Number(integer))
    if (numbers.length > 1) {
      numbers.push("custom")
    }
    return numbers
  }

  const handleAddToCart = () => {
    const isOver = maxQuantityHit(
      quantity === "custom" ? customQuantity : quantity
    )
    if (isOver) {
      return
    }

    pushEvent({ eventCode: "AddToCart" })

    addVariantToCart({
      shopifyId: variant.shopifyId,
      quantity: quantity === "custom" ? customQuantity : quantity,
      limit: Number(productFromProps.orderLimit?.value),
      productId: productFromProps.shopifyId,
    })
  }

  const maxQuantityHit = addedQty => {
    let allVariants = props.checkout?.lineItems
      ? props.checkout?.lineItems.filter(
          i => i?.variant?.product?.id == productFromProps?.shopifyId
        )
      : []
    let totalQty = 0
    if (allVariants.length) {
      totalQty = allVariants.reduce((sum, obj) => sum + obj.quantity, 0)
    }

    if (!totalQty) {
      return false
    }

    if (addedQty) {
      if (totalQty + addedQty > Number(productFromProps.orderLimit?.value)) {
        return true
      }
    }

    if (totalQty >= Number(productFromProps.orderLimit?.value)) {
      return true
    }

    return false
  }

  const variantSelectors = hasVariants
    ? modalProduct.options.map(option => (
        <VariantSelector
          onChange={handleOptionChange}
          key={option.shopifyId.toString()}
          option={option}
          selectedOptions={selectedOptions}
        />
      ))
    : null

  const compareAtPercentage = percentageOff(
    variant.price,
    variant.compareAtPrice
  )

  return (
    <div>
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", null, "row"],
          justifyContent: "space-between",
        }}
      >
        <div
          sx={{
            position: "relative",
            width: ["100%", null, "48%"],
          }}
        >
          <Carousel
            sliderRef={sliderRef}
            setSlide={setSelectedImageIndex}
            containerStyle={{
              width: "100%",
              mx: "auto",
              "button.slick-prev": {
                zIndex: 10,
                left: rem(10),
                height: rem(40),
                width: rem(40),
                backgroundColor: "white",
                "&:before": {
                  content: "''",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: "auto",
                  backgroundImage: `url('/icons/carrotLeft.svg')`,
                  height: rem(20),
                  width: rem(20),
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                },
                "&:focus": {
                  outline: `${theme.colors.purple} solid 2px`,
                },
              },
              "button.slick-next": {
                right: rem(10),
                height: rem(40),
                width: rem(40),
                backgroundColor: "white",
                "&:before": {
                  content: "''",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: "auto",
                  backgroundImage: `url('/icons/carrotRight.svg')`,
                  height: rem(20),
                  width: rem(20),
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                },
                "&:focus": {
                  outline: `${theme.colors.purple} solid 2px`,
                },
              },
            }}
            settings={{
              dots: false,
            }}
          >
            {modalProduct.media &&
              modalProduct.media.map(({ image }, index) => (
                <img
                  src={image.originalSrc}
                  key={index}
                  alt={`Product ${index + 1}`}
                  sx={{
                    width: "100%",
                  }}
                />
              ))}
          </Carousel>

          {compareAtPercentage ? (
            <div
              sx={{
                "z-index": 20,
                position: "absolute",
                top: 3,
                right: 0,
                backgroundColor: "red",
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100,
                color: "white",
                fontSize: "16px",
                lineHeight: 0.95,
                pt: rem(7),
                pb: rem(10),
                px: rem(16),
              }}
            >
              {compareAtPercentage}% off
            </div>
          ) : null}

          <Grid
            gap={1}
            columns={[3, null, null, 4]}
            sx={{
              display: ["none", null, "block"],
            }}
          >
            {modalProduct.media &&
              modalProduct.media.length > 1 &&
              modalProduct.media.map(({ image }, index) => (
                <button
                  key={index}
                  aria-label={`Select image ${index + 1} in carousel`}
                  onClick={() => sliderRef.current.slickGoTo(index)}
                  sx={{
                    cursor: "pointer",
                    "&:focus": {
                      img: {
                        border: `2px solid ${theme.colors.purple}`,
                      },
                    },
                  }}
                >
                  <Image
                    src={image.originalSrc}
                    key={index}
                    variant="productImageListItem"
                    sx={{
                      border:
                        selectedImageIndex === index
                          ? `2px solid ${theme.colors.darkPurple}`
                          : `2px solid transparent`,
                    }}
                  />
                </button>
              ))}
          </Grid>
        </div>

        <div sx={{ width: ["100%", null, "48%"] }}>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", null, "block"],
              mb: 5,
              mt: [rem(10), null, 0],
            }}
          >
            <Themed.h4
              sx={{
                margin: 0,
                mr: 3,
                mb: [rem(10)],
              }}
            >
              {modalProduct.title}
            </Themed.h4>
            <div
              style={{
                m: 0,
                p: 0,
              }}
            >
              <Price
                price={variant.price}
                compareAtPrice={variant.compareAtPrice}
                available={available}
              />
            </div>
          </div>
          <div sx={{ mb: 5 }}>{variantSelectors}</div>
          <div sx={{ my: "50px" }}>
            <Themed.h5
              sx={{
                mb: 2,
              }}
            >
              Quantity
            </Themed.h5>
            {qtyItems.map(option => (
              <ButtonOption
                key={option}
                selected={option === quantity}
                disabled={option > variant.inventoryQuantity}
                onClick={() => {
                  setQuantity(option)
                }}
              >
                {option}
              </ButtonOption>
            ))}

            {quantity === "custom" && (
              <QuantitySelector
                quantity={customQuantity}
                handleQuantityChange={handleCustomQuantityChange}
                limit={productFromProps.orderLimit?.value}
              />
            )}

            {productFromProps.orderLimit?.value ? (
              <p>
                This product is limited to {productFromProps.orderLimit.value}{" "}
                per customer
              </p>
            ) : null}
          </div>
          <div
            sx={{
              display: "flex",
              alignItems: ["inherit", "center"],
              flexDirection: ["column", "row"],
              mb: 5,
            }}
          >
            <Button
              disabled={!available || cartAdding || maxQuantityHit()}
              onClick={handleAddToCart}
              bg="darkPurple"
              color="white"
              styles={{
                minWidth: [null, null, rem(250), rem(285)],
              }}
            >
              {cartAdding
                ? "Adding..."
                : available
                ? "Add to Bag"
                : "Out of stock"}
            </Button>
            <div
              sx={{
                mt: [2, 0],
                display: "inline-flex",
                alignItems: "center",
                justifyContent: ["center", "inherit"],
                color: "darkPurple",
                "&:hover": {
                  color: "purple",
                },
                "&:hover button": {
                  color: "purple",
                  borderColor: "purple",
                },
              }}
            >
              <button
                sx={{
                  ml: [0, 3],
                  variant: "typography.link",
                  color: "currentColor",
                }}
                className="link-heading"
                onClick={() => {
                  closeProductModal()
                  window.scrollTo({ top: 0, behavior: "smooth" })
                  props.openCartModal()
                }}
              >
                View Bag
              </button>
              <div
                sx={{
                  width: rem(13),
                  transform: "translateX(8px)",
                }}
              >
                <ArrowRight width="100%" fill="currentColor" />
              </div>
            </div>
          </div>

          <InfoDrop name="Description">
            <div
              dangerouslySetInnerHTML={{ __html: modalProduct.descriptionHtml }}
              sx={{
                p: {
                  marginBottom: rem(16),
                },
                "p + ul, p + ol": {
                  marginTop: 0,
                },
              }}
            ></div>
          </InfoDrop>
          <InfoDrop name="Shipping">
            <p>
              Orders are usually shipped within two business days. Any express
              orders placed before 11am are usually shipped the same day.
            </p>
          </InfoDrop>
          <InfoDrop name="Refund">
            <p>
              Something not right with your order? Reach out to us via email at{" "}
              <a href="mailto:orders@minus18.org.au">orders@minus18.org.au</a>.
              We do allow refunds within 30 days of purchase in accordance with
              our <a href="/terms-and-conditions">Refund Policy</a>.
            </p>
          </InfoDrop>
        </div>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    modalProductFromStore: state.cartModal.modalProduct,
    cartAdding: state.cart.adding,
    checkout: state.cart.checkout,
  }),
  dispatch => ({
    addVariantToCart: dispatch.cart.addVariantToCart,
    closeProductModal: () => dispatch.cartModal.toggleProductModal(false),
    openCartModal: () => dispatch.cartModal.toggleCartModal(true),
  })
)(ProductModal)
