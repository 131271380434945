/** @jsx jsx */
import { jsx } from "theme-ui"
import { Themed } from "@theme-ui/mdx"
import { useState } from "react"
import FocusLock from "react-focus-lock"
import axios from "axios"
import { connect } from "react-redux"
import { Formik } from "formik"
import * as Yup from "yup"
import get from "lodash/get"
import Button from "@components/Button"
import { rem } from "@src/theme"
import TextInput from "@components/form/TextInput"
import FieldSpacer from "@components/form/FieldSpacer"
import SelectInput from "@components/form/SelectInput"
import TextArea from "@components/form/TextArea"
import { Grid } from "@theme-ui/components"
import useGTM from "@src/utils/useGTM"

const MAILCHIMP_API_KEY = "0587f871cde5c540afeaa4e7668e288c-us5"

const MailchimpModal = ({ closeMailchimpModal, mailchimpTag }) => {
  // const mailchimpClient = new Mailchimp(MAILCHIMP_API_KEY)

  const [success, setSuccess] = useState(null)
  const [submissionError, setSubmissionError] = useState(null)
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .required("This is required"),
    origin: Yup.string().required("This is required"),
    plans: Yup.string(),
    speaker: Yup.string(),
    fundraising: Yup.string(),
    state: Yup.string(),
  })

  const { pushEvent } = useGTM()

  const initialFormState = {
    email: "",
    name: "",
    origin: "Business",
    planning: "",
    speaker: "No",
    fundraising: "No",
    state: "ACT",
    opt_in_subscribe: [],
  }

  const originOptions = [
    {
      label: "Business",
      value: "Business",
    },
    {
      label: "School",
      value: "School",
    },
    {
      label: "Uni or TAFE",
      value: "Uni or TAFE",
    },
    {
      label: "Local Council",
      value: "Local council",
    },
    {
      label: "NFP",
      value: "NFP",
    },
    {
      label: "Individual",
      value: "Individual",
    },
    {
      label: "Other",
      value: "Other",
    },
  ]

  const stateOptions = [
    { label: "ACT", value: "ACT" },
    { label: "New South Wales", value: "NSW" },
    { label: "Northern Territory", value: "NT" },
    { label: "Queensland", value: "QLD" },
    { label: "South Australia", value: "SA" },
    { label: "Tasmania", value: "TAS" },
    { label: "Victoria", value: "VIC" },
    { label: "Western Australia", value: "WA" },
  ]

  const getError = (name, errors, touched) =>
    get(touched, name) && get(errors, name) ? get(errors, name) : ""

  return (
    <div>
      <FocusLock>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialFormState}
          onSubmit={async (values, actions) => {
            const dt = new Date()
            setLoading(true)

            const mailchimpLambdaOptions = {
              url: "/.netlify/functions/toolkitDownload",
              method: "post",
              data: {
                first_name: values.name,
                email_address: values.email,
                opted_in: "subscribed",
                tag: `${mailchimpTag} ${dt.getFullYear()}`,
                speaker: values.speaker,
                planning: values.planning,
                origin: values.origin,
                fundraising: values.fundraising,
                state: values.state,
              },
            }

            axios(mailchimpLambdaOptions)
              .then(response => {
                if (!response) {
                  throw new Error()
                }
                if (submissionError || success) {
                  setSubmissionError(null)
                  setSuccess(null)
                }

                setSuccess("We'll be in touch!")
                setLoading(false)

                pushEvent({ event: "toolkitDownload" })
              })
              .catch(err => {
                console.log(err)
              })
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <form>
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Themed.h5
                  sx={{
                    mt: 0,
                  }}
                >
                  Enter your details
                </Themed.h5>
              </div>
              <FieldSpacer>
                <Grid gap={4} columns={[1, 1, 2]}>
                  <TextInput
                    value={values.name}
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    error={getError("name", errors, touched)}
                    onBlur={handleBlur}
                    required
                  />
                  <TextInput
                    value={values.email}
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    error={getError("email", errors, touched)}
                    onBlur={handleBlur}
                    required
                  />
                </Grid>
              </FieldSpacer>

              <FieldSpacer>
                <Grid gap={4} columns={[1, 1, 2]}>
                  <SelectInput
                    value={values.state}
                    options={stateOptions}
                    label="State"
                    name="state"
                    onChange={handleChange}
                    error={getError("state", errors, touched)}
                    onBlur={handleBlur}
                    required
                  />
                  <SelectInput
                    value={values.origin}
                    options={originOptions}
                    label="I'm from a"
                    name="origin"
                    onChange={handleChange}
                    error={getError("origin", errors, touched)}
                    onBlur={handleBlur}
                    required
                  />
                </Grid>
              </FieldSpacer>

              <FieldSpacer>
                <TextArea
                  name="planning"
                  value={values.planning}
                  label="Tell us a bit about what you’re planning"
                  onChange={handleChange}
                  error={getError("planning", errors, touched)}
                  onBlur={handleBlur}
                  small
                />
              </FieldSpacer>

              <FieldSpacer>
                <Grid gap={4} columns={[1, 1, 2]}>
                  <SelectInput
                    name="speaker"
                    label="Are you interested in booking a paid speaker or training session?"
                    options={[
                      {
                        label: "No",
                        value: "No",
                      },
                      {
                        label: "Yes",
                        value: "Yes",
                      },
                    ]}
                    value={values.speaker}
                    onChange={handleChange}
                    error={getError("speaker", errors, touched)}
                    onBlur={handleBlur}
                  />
                </Grid>
              </FieldSpacer>

              <FieldSpacer>
                <Grid gap={4} columns={[1, 1, 2]}>
                  <SelectInput
                    name="fundraising"
                    label="Are you planning on fundraising?"
                    options={[
                      {
                        label: "No",
                        value: "No",
                      },
                      {
                        label: "Yes",
                        value: "Yes",
                      },
                      {
                        label: "Unsure",
                        value: "Unsure",
                      },
                    ]}
                    value={values.fundraising}
                    onChange={handleChange}
                    error={getError("fundraising", errors, touched)}
                    onBlur={handleBlur}
                  />
                </Grid>
              </FieldSpacer>

              <FieldSpacer>
                <span
                  sx={{
                    transform: "translateY(3px)",
                  }}
                >
                  Check your inbox, we’ll send your toolkit and other Minus18
                  goodies!
                </span>
              </FieldSpacer>

              <div
                sx={{
                  height: "100%",
                  mt: rem(20),
                }}
              >
                {success ? <p>We'll be in touch!</p> : null}
                <Button onClick={handleSubmit} type="submit">
                  {loading ? "..." : success ? "Sent" : "Submit"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </FocusLock>
    </div>
  )
}

export default connect(
  state => ({
    mailchimpTag: state.mailchimpModal.tag,
  }),
  dispatch => ({
    closeMailchimpModal: () => dispatch.mailchimpModal.toggleModal(false),
  })
)(MailchimpModal)
