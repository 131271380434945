/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import PropTypes from "prop-types"
import * as _ from "lodash"
import SelectInput from "@shop/components/SelectInput"
import { Themed } from "@theme-ui/mdx"
import styled from "styled-components"
import theme, { rem } from "@src/theme"

const VariantSelector = ({ selectedOptions, option, onChange, value }) => {
  const { name } = option

  const handleOptionChange = e => {
    onChange({ name, value: e.target.value })
  }

  return (
    <div>
      <Themed.h5
        sx={{
          mb: 2,
        }}
      >
        {name}
      </Themed.h5>
      {option.values.length > 5 ? (
        <SelectInput
          options={option.values}
          onChange={handleOptionChange}
          value={value}
        />
      ) : (
        option.values.map(val => (
          <ButtonOption
            key={val}
            selected={
              val === selectedOptions.find(option => option.name === name).value
            }
            onClick={() => onChange({ name, value: val })}
          >
            {val}
          </ButtonOption>
        ))
      )}
    </div>
  )
}

VariantSelector.propTypes = {
  onChange: PropTypes.func,
  option: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
}

export const ButtonOption = styled.button`
  position: relative;
  margin-right: ${rem(10)};
  margin-bottom: ${rem(10)};
  min-width: ${rem(60)};
  height: ${rem(40)};
  padding: ${rem(9)} ${rem(16)} ${rem(11)};
  font-size: ${rem(14)};
  color: ${theme.colors.darkPurple};
  border: 1px solid ${theme.colors.darkPurple};
  outline: ${props =>
    props.selected ? `2px solid ${theme.colors.darkPurple};` : "none"};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    color: ${theme.colors.grey};
    outline-color: ${theme.colors.grey};
    cursor: not-allowed;

    // Draw a diagonal line from bottom left to top right
    background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      ${theme.colors.grey} 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
  }
`

export default VariantSelector
