export const mailchimpModal = {
    state: {
        open: false,
        tag: "",
    },
    reducers: {
        toggleModal(state, payload) {
            return {
                ...state,
                open: payload,
            }
        },
        setMailchimpTag(state, payload) {
            return {
                ...state,
                tag: payload,
            }
        },
    },
}
