/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components/macro"
import { connect } from "react-redux"
import theme, { rem } from "@src/theme"

const Hamburger = ({ toggleNav, navOpen, cartModalOpen, closeCartModal }) => (
  <div
    onClick={() => {
      if (cartModalOpen) {
        closeCartModal()
      }
      toggleNav()
    }}
    sx={{
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    }}
  >
    <Container menuOpen={navOpen}>
      <Line menuOpen={navOpen} />
      <Line menuOpen={navOpen} />
      <Line menuOpen={navOpen} />
    </Container>
    <p
      sx={{
        fontFamily: "heading",
        fontWeight: 900,
        textTransform: "uppercase",
        transform: "translate(8px, 2px)",
        fontSize: [rem(14), rem(18)],
      }}
    >
      Menu
    </p>
  </div>
)

export default connect(
  state => ({
    cartModalOpen: state.cartModal.cartModalOpen,
    navOpen: state.ui.navOpen,
  }),
  dispatch => ({
    closeCartModal: () => dispatch.cartModal.toggleCartModal(false),
    toggleNav: () => dispatch.ui.toggleNav(),
  })
)(Hamburger)

const Container = styled.button`
  width: ${rem(16)};
  height: 14px;
  position: relative;
  cursor: pointer;
  color: ${theme.colors.darkPurple};
  align-self: center;
`

const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: currentColor;
  position: absolute;
  top: 0;
  left: 0;

  &:nth-child(1) {
    top: 0;
    transition: top 0.15s linear 0.15s, transform 0.15s linear 0s;
    ${props =>
    props.menuOpen &&
    `
      top: 50%;
      transform: rotate(45deg);
      transition: top 0.15s linear, transform 0.15s linear 0.15s;
    `};
  }

  &:nth-child(2) {
    top: calc(50% - 1px);
    transition: transform 0.15s linear 0s;
    ${props =>
    props.menuOpen &&
    `
      top: 50%;
      transform: rotate(-45deg);
      transition: transform 0.15s linear 0.15s;
    `};
  }

  &:nth-child(3) {
    top: calc(100% - 2px);
    transition: top 0.15s linear 0.15s, transform 0.15s linear 0s;
    ${props =>
    props.menuOpen &&
    `
      top: 50%;
      transform: rotate(-45deg);
      transition: top 0.15s linear, transform 0.15s linear 0.15s;
    `};
  }
`
