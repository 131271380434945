import moment from "moment"
import remark from "remark"
import remarkHTML from "remark-html"

export const upcomingEventsFilter = ({ node }) => {
  if (
    moment(node.end.local).isBefore(moment()) ||
    node.status === "canceled" ||
    node.status === "completed" ||
    node.status === "draft"
  ) {
    return false
  }

  return true
}
export default {
  upcomingEventsFilter,
}

export const formatMarkdownToHtml = value =>
  remark()
    .use(remarkHTML)
    .processSync(value)
    .toString()

export const isBrowser = typeof window !== "undefined" && window
