/** @jsx jsx */
import React, { useEffect } from "react"
import { jsx } from "theme-ui"
import { connect } from "react-redux"

import styled from "styled-components"
import Nav from "@components/Nav"
import ProductModal from "@shop/components/ProductModal"
import DonationModal from "@components/DonationModal"
import MailchimpModal from "@modules/MailchimpModal"
import ModalOverlay from "@components/ModalOverlay"
import ModalOverlaySmall from "@components/ModalOverlaySmall"
import MobileNav from "@components/MobileNav"
import theme from "@src/theme"

import Footer from "@components/Footer"
import "../../fonts/fonts.css"
import { GlobalStyle } from "@src"
/**
 * @note
 * With the nav, we currently have a custom container on pages that have the three column grid. So it aligns with content.
 * To remove that custom container styling, create prop that gets passed from each page into  this <Layout/> component which then feeds into nav.
 */

const borders = {
  rainbow: () => (
    <React.Fragment>
      <RainbowTop />
      <RainbowRight />
      <RainbowBot />
      <RainbowLeft />
    </React.Fragment>
  ),
  trans: () => (
    <React.Fragment>
      <TransTop />
      <TransRight />
      <TransBot />
      <TransLeft />
    </React.Fragment>
  ),
}

export default connect(
  state => ({
    productModalOpen: state.cartModal.productModalOpen,
    cartModalOpen: state.cartModal.cartModalOpen,
    donationModalOpen: state.donationModal.open,
    mailchimpModalOpen: state.mailchimpModal.open,
    navOpen: state.ui.navOpen,
  }),
  dispatch => ({
    closeProductModal: () => dispatch.cartModal.toggleProductModal(false),
    refetchCheckout: () => dispatch.cart.refetchCheckout(false),
    closeCartModal: () => dispatch.cartModal.toggleCartModal(false),
    closeDonationModal: () => dispatch.donationModal.toggleDonationModal(false),
    closeMailchimpModal: () => dispatch.mailchimpModal.toggleModal(false),
  })
)(
  ({
    children,
    border = "rainbow",
    productModalOpen,
    closeProductModal,
    donationModalOpen,
    closeDonationModal,
    mailchimpModalOpen,
    closeMailchimpModal,
    refetchCheckout,
    navOpen,
  }) => {
    useEffect(() => {
      // Refetch checkout every 5 seconds so that adding items to cart in other tabs is reflected
      const intervalId = setInterval(() => {
        refetchCheckout()
      }, 5000)

      return () => clearInterval(intervalId)
    }, [])

    return (
      <React.Fragment>
        <ModalOverlay active={productModalOpen} onClose={closeProductModal}>
          <ProductModal />
        </ModalOverlay>

        <ModalOverlay
          active={donationModalOpen}
          onClose={closeDonationModal}
          noPadding
        >
          <DonationModal />
        </ModalOverlay>

        <ModalOverlay active={mailchimpModalOpen} onClose={closeMailchimpModal}>
          <MailchimpModal />
        </ModalOverlay>

        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            padding: "6px",
            position: "relative",
          }}
        >
          <SkipLink
            href="#content"
            sx={{
              variant: "buttons.primary",
            }}
          >
            Skip to content
          </SkipLink>
          {borders[border]()}

          <header
            sx={{
              width: "100%",
            }}
          >
            {!navOpen && <Nav />}
            {navOpen && <MobileNav />}
          </header>
          <main
            id="content"
            sx={{
              width: "100%",
              flex: "1 1 auto",
            }}
          >
            {children}
          </main>

          <Footer />
        </div>
      </React.Fragment>
    )
  }
)

const SkipLink = styled.a`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  left: -9999px;
  width: 1px;
  z-index: 99;
  background-color: ${theme.colors.darkPurple};
  color: ${theme.colors.white};

  &:focus {
    top: 5px;
    left: 5px;
  }

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: 250px;
  }
`
const Rainbow = styled.div`
  position: fixed;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
`

const TransTop = styled(Rainbow)`
  background: rgb(78, 201, 245);
  background: linear-gradient(
    90deg,
    rgba(78, 201, 245, 1) 0%,
    rgba(78, 201, 245, 1) 20%,
    rgba(243, 145, 188, 1) 20%,
    rgba(243, 145, 188, 1) 40%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 60%,
    rgba(243, 145, 188, 1) 60%,
    rgba(243, 145, 188, 1) 80%,
    rgba(78, 201, 245, 1) 80%,
    rgba(78, 201, 245, 1) 100%
  );
  top: 0;
  width: 100%;
  height: 6px;
  z-index: 99;
`
const TransBot = styled(Rainbow)`
  background: rgb(78, 201, 245);
  background: linear-gradient(
    90deg,
    rgba(78, 201, 245, 1) 0%,
    rgba(78, 201, 245, 1) 20%,
    rgba(243, 145, 188, 1) 20%,
    rgba(243, 145, 188, 1) 40%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 60%,
    rgba(243, 145, 188, 1) 60%,
    rgba(243, 145, 188, 1) 80%,
    rgba(78, 201, 245, 1) 80%,
    rgba(78, 201, 245, 1) 100%
  );
  bottom: 0;
  width: 100%;
  height: 6px;
  z-index: 99;
`
const TransRight = styled(Rainbow)`
  background: rgb(78, 201, 245);
  background: linear-gradient(
    0deg,
    rgba(78, 201, 245, 1) 0%,
    rgba(78, 201, 245, 1) 20%,
    rgba(243, 145, 188, 1) 20%,
    rgba(243, 145, 188, 1) 40%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 60%,
    rgba(243, 145, 188, 1) 60%,
    rgba(243, 145, 188, 1) 80%,
    rgba(78, 201, 245, 1) 80%,
    rgba(78, 201, 245, 1) 100%
  );
  right: 0;
  width: 6px;
  height: 100%;
`
const TransLeft = styled(Rainbow)`
  background: rgb(78, 201, 245);
  background: linear-gradient(
    0deg,
    rgba(78, 201, 245, 1) 0%,
    rgba(78, 201, 245, 1) 20%,
    rgba(243, 145, 188, 1) 20%,
    rgba(243, 145, 188, 1) 40%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 60%,
    rgba(243, 145, 188, 1) 60%,
    rgba(243, 145, 188, 1) 80%,
    rgba(78, 201, 245, 1) 80%,
    rgba(78, 201, 245, 1) 100%
  );
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
`

const RainbowTop = styled(Rainbow)`
  background: rgb(243, 115, 97);
  background: linear-gradient(
    90deg,
    rgba(243, 115, 97, 1) 0%,
    rgba(243, 115, 97, 1) 16.6%,
    rgba(248, 155, 60, 1) 16.6%,
    rgba(248, 155, 60, 1) 33.2%,
    rgba(252, 223, 92, 1) 33.2%,
    rgba(252, 223, 92, 1) 49.36%,
    rgba(163, 187, 74, 1) 49.36%,
    rgba(163, 187, 74, 1) 65.96%,
    rgba(91, 199, 190, 1) 65.96%,
    rgba(91, 199, 190, 1) 82.56%,
    rgba(106, 80, 149, 1) 82.56%,
    rgba(106, 80, 149, 1) 100%
  );
  top: 0;
  width: 100%;
  height: 6px;
  z-index: 99;
`
const RainbowBot = styled(Rainbow)`
  background: rgb(106, 80, 149);
  background: linear-gradient(
    90deg,
    rgba(106, 80, 149, 1) 0%,
    rgba(106, 80, 149, 1) 16.6%,
    rgba(91, 199, 190, 1) 16.6%,
    rgba(91, 199, 190, 1) 33.2%,
    rgba(163, 187, 74, 1) 33.2%,
    rgba(163, 187, 74, 1) 49.36%,
    rgba(252, 223, 92, 1) 49.36%,
    rgba(252, 223, 92, 1) 65.96%,
    rgba(248, 155, 60, 1) 65.96%,
    rgba(248, 155, 60, 1) 82.56%,
    rgba(243, 115, 97, 1) 82.56%,
    rgba(243, 115, 97, 1) 100%
  );

  bottom: 0;
  width: 100%;
  height: 6px;
  z-index: 99;
`
const RainbowRight = styled(Rainbow)`
  background: rgb(243, 115, 97);
  background: linear-gradient(
    0deg,
    rgba(243, 115, 97, 1) 0%,
    rgba(243, 115, 97, 1) 16.6%,
    rgba(248, 155, 60, 1) 16.6%,
    rgba(248, 155, 60, 1) 33.2%,
    rgba(252, 223, 92, 1) 33.2%,
    rgba(252, 223, 92, 1) 49.36%,
    rgba(163, 187, 74, 1) 49.36%,
    rgba(163, 187, 74, 1) 65.96%,
    rgba(84, 193, 184, 1) 65.96%,
    rgba(84, 193, 184, 1) 82.56%,
    rgba(106, 80, 149, 1) 82.56%,
    rgba(106, 80, 149, 1) 100%
  );

  right: 0;
  width: 6px;
  height: 100%;
`
const RainbowLeft = styled(Rainbow)`
  background: rgb(106, 80, 149);
  background: linear-gradient(
    0deg,
    rgba(106, 80, 149, 1) 0%,
    rgba(106, 80, 149, 1) 16.6%,
    rgba(91, 199, 190, 1) 16.6%,
    rgba(91, 199, 190, 1) 33.2%,
    rgba(163, 187, 74, 1) 33.2%,
    rgba(163, 187, 74, 1) 49.36%,
    rgba(252, 223, 92, 1) 49.36%,
    rgba(252, 223, 92, 1) 65.96%,
    rgba(248, 155, 60, 1) 65.96%,
    rgba(248, 155, 60, 1) 82.56%,
    rgba(243, 115, 97, 1) 82.56%,
    rgba(243, 115, 97, 1) 100%
  );

  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
`
