/** @jsx jsx */
import { jsx } from "theme-ui"
import { rem } from "@src/theme"

export const XSmall = ({ styles, ...props }) => (
  <div
    sx={{
      height: [rem(24)],
      ...styles,
    }}
  ></div>
)

export const Small = ({ styles, ...props }) => (
  <div
    sx={{
      height: [rem(36)],
      ...styles,
    }}
  ></div>
)
export const Medium = ({ styles, ...props }) => (
  <div
    sx={{
      height: [rem(40), rem(56)],
      ...styles,
    }}
  ></div>
)
export const Large = ({ styles, ...props }) => (
  <div
    sx={{
      height: [rem(60), null, rem(100)],
      ...styles,
    }}
  ></div>
)

export default {
  XSmall,
  Small,
  Medium,
  Large,
}
