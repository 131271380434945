/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import { Themed } from "@theme-ui/mdx"
import theme, { rem } from "@src/theme"
const Main = ({
  title,
  small,
  bannerBg = theme.colors.darkPurple,
  bannerColor = "white",
  ...props
}) => {
  const brokenTitle = title.match(/.{1,30}(\s|$)/g)
  return (
    <Banner
      sx={{
        px: rem(5),
        pr: rem(8),
        pb: rem(4.5),
        pt: rem(9),
        display: "inline-block",
        transform: `rotate(-0.5deg)`,
      }}
      {...props}
      small={small}
    >
      {small ? (
        <Themed.h4
          sx={{
            color: bannerColor,
            fontStyle: "italic",
            margin: 0,
          }}
        >
          {title}
        </Themed.h4>
      ) : (
        <Themed.h3
          sx={{
            color: bannerColor,
            fontStyle: "italic",
            margin: 0,
          }}
        >
          <span
            sx={{
              whiteSpace: "pre-wrap",
              backgroundColor: bannerBg,
              borderLeft: `0.1em solid ${bannerBg}`,
              borderRight: `0.2em solid ${bannerBg}`,
            }}
          >
            {title}
          </span>
        </Themed.h3>
      )}
    </Banner>
  )
}

export const CenteredAbsoluteBanner = ({ title, fontSize }) => (
  <div
    sx={{
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      left: 0,
      right: 0,
      margin: "0 auto",
      bottom: "13.2px",
      width: title && title.length >= 30 ? rem(650) : "auto",
      maxWidth: "90%",
    }}
  >
    <Main title={title} fontSize={fontSize} />
  </div>
)

export default Main

const Banner = styled.div`
  background-color: ${props =>
    props.small ? theme.colors.darkPurple : "transparent"};
  margin: 0 auto;
  text-align: center;
`
