/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import theme, { rem } from "@src/theme"

const TextArea = ({
  value,
  label,
  name,
  onChange,
  error,
  onBlur,
  required,
  helpText,
  maxlength,
  placeholder,
  small = false,
}) => {
  return (
    <label
      htmlFor={name}
      sx={{
        display: "block",
      }}
    >
      {label && (
        <Label>
          {label}
          {required && "*"}
        </Label>
      )}
      {helpText && <HelpText>{helpText}</HelpText>}
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        small={small}
        maxlength={maxlength}
        placeholder={placeholder}
        sx={{
          border: `3px solid ${theme.colors.grey}`,
          padding: rem(8),
          fontSize: rem(18),
          width: "100%",
          height: small ? rem(125) : rem(250),
          fontFamily: theme.fonts.body,
          borderRadius: 0,
          color: theme.colors.darkPurple,
        }}
      ></textarea>
      {error && <p sx={{ color: "rainbowRed", mt: 1, mb: 0 }}>{error}</p>}
    </label>
  )
}

export default TextArea

const Label = styled.span`
  display: block;
  font-size: ${rem(18)};
  margin-bottom: ${rem(14)};
  font-family: ${theme.fonts.heading};
  font-weight: 900;
  text-transform: uppercase;
`

const HelpText = styled.span`
  display: block;
  margin-bottom: ${rem(14)};
  margin-top: ${rem(-14)};
`
