/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Banner from "@components/Banner"
import { rem } from "@src/theme"
import { Themed } from "@theme-ui/mdx"

import arrowRight from "@components/assets/svg/carrotRight.svg"
import arrowLeft from "@components/assets/svg/carrotLeft.svg"

export const CarouselTextCallout = ({
  heading,
  children,
  bannerColor,
  bannerBg,
}) => (
  <div>
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        sx={{
          transform: "rotate(-0.75deg)",
        }}
      >
        <Banner
          bannerColor={bannerColor}
          bannerBg={bannerBg}
          title={heading}
        />
      </div>
    </div>

    <Themed.h3
      sx={{
        textAlign: "center",
        color: bannerBg,
        textTransform: "unset",
      }}
    >
      {children}
    </Themed.h3>
  </div>
)

const Carousel = ({
  sliderRef,
  setSlide,
  settings,
  containerStyle,
  children,
}) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slider",
    beforeChange: (current, next) => {
      if (setSlide) {
        setSlide(next)
      }
    },
    ...settings,
  }

  return (
    <SliderContainer
      sx={{
        "button.slick-next": {
          /* right: 17%; */
          "&:before": {
            content: "''",
            backgroundImage: `url('/icons/carrotRight.svg')`,
            height: [rem(18), rem(20), rem(24)],
            width: [rem(18), rem(20), rem(24)],
            display: "block",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          },
        },
        "button.slick-prev": {
          /* right: 17%; */
          "&:before": {
            content: "''",
            backgroundImage: `url('/icons/carrotLeft.svg')`,
            height: [rem(18), rem(20), rem(24)],
            width: [rem(18), rem(20), rem(24)],
            display: "block",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          },
        },
        ...containerStyle,
      }}
    >
      <Slider ref={sliderRef} {...sliderSettings}>
        {children}
      </Slider>
    </SliderContainer>
  )
}

export default Carousel

const SliderContainer = styled.div`
  .slick-dots {
    button:before {
      font-size: 16px;
      opacity: 1;
      color: #fff;
    }

    li.slick-active {
      button:before {
        color: #3a2a55;
      }
    }
  }

  /* button.slick-next { */
  /* right: 17%; */

    /* &:before {
      content: "";
      background-image: url(${arrowRight});
      height: 30px;
      width: 30px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  button.slick-prev {
    /* left: 17%; */
    /* &:before {
      content: "";
      background-image: url(${arrowLeft});
      height: 30px;
      width: 30px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
    } */
  /* }  */
`
