/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import carrotDown from "@components/assets/icons/carrot-down.svg"
import { rem } from "@src/theme"

const SelectInput = ({ options, value, onChange, ...props }) => {
  return (
    <Outer
      sx={{
        border: "3px solid",
        borderColor: "grey",
        py: rem(13),
        px: 1,
        fontSize: rem(15),
        width: 172,
        fontFamily: "body",
        borderRadius: 0,
        color: "darkPurple",
      }}
      onChange={onChange}
      value={value}
    >
      {options.map(o => (
        <option value={o}>{o}</option>
      ))}
    </Outer>
  )
}

export default SelectInput

const Outer = styled.select`
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: url(${carrotDown}) no-repeat right #ddd;
  background-position-x: 143px;
  background-color: transparent;
`
