export const cartModal = {
    state: {
        productModalOpen: false,
        modalProduct: null,
        cartModalOpen: false,
    },
    reducers: {
        toggleProductModal(state, payload) {
            return {
                ...state,
                productModalOpen: payload,
            }
        },
        toggleCartModal(state, payload) {
            return {
                ...state,
                cartModalOpen: payload,
            }
        },
        setModalProduct(state, payload) {
            return {
                ...state,
                modalProduct: payload,
            }
        },
    },
}
