/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import theme, { rem } from "@src/theme"
import Icon from "@components/Icon"
function Checkbox({
  fieldValue,
  value,
  label,
  form,
  name,
  setFieldValue,
  icon,
  variant,
  styles,
}) {
  const checked =
    typeof fieldValue === "boolean" ? fieldValue : fieldValue.includes(value)
  const Component =
    variant === "block"
      ? WrapperLabelBlock
      : variant === "tick"
      ? WrapperLabelTick
      : WrapperLabel

  return (
    <Component checked={checked} sx={{ ...styles }}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={() => {
          if (typeof fieldValue === "boolean") {
            setFieldValue(name, fieldValue)
          } else if (fieldValue.includes(value)) {
            const nextValue = fieldValue.filter(v => v !== value)
            setFieldValue(name, nextValue)
          } else {
            const nextValue = fieldValue.concat(value)
            setFieldValue(name, nextValue)
          }
        }}
      />
      {variant === "block" ? (
        <div className="input">
          <div className="checkmark" />
        </div>
      ) : (
        <span className="checkmark" />
      )}
      {label || value}
      {icon ? (
        <Icon
          name={icon}
          styles={{ ml: rem(8), maxWidth: rem(32), display: "flex" }}
        />
      ) : null}
    </Component>
  )
}

export default Checkbox

const WrapperLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .checkmark {
    border-color: #bbbaba;
  }

  input:checked ~ .checkmark {
    background-color: #61488a;
    border-color: #3a2a55;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    margin-right: 10px;
    height: 16px;
    width: 16px;
    background-color: white;
    border: 2px solid #e0e0e0;
    transform: translateY(7px);
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
    background-color: #3a2a55;
  }
`

const WrapperLabelTick = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // &:hover input ~ .checkmark {
  //   border-color: #bbbaba;
  // }

  input:checked ~ .checkmark {
    border-color: ${theme.colors.darkPurple};
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 2px solid ${theme.colors.darkPurple};
    border-radius: 50%;
    transform: translateY(7px);
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
    top: -3px;
    left: 5px;
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none"><path d="M5.20906 10.1691L2.00005 7L0 8.99983L5.21347 14L16 2.00003L13.9815 0L5.20906 10.1691Z" fill="%233A2A55"/></svg>');
  }
`

const WrapperLabelBlock = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  padding: ${rem(10)} ${rem(10)};
  margin-right: ${rem(10)};
  margin-bottom: ${rem(10)};
  border: 2px solid ${theme.colors.darkPurple};
  border-radius: 4px;
  ${({ checked }) => (checked ? "background: white;" : "")}

  &:focus-within {
    outline: 2px solid ${theme.colors.purple};
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .input {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-right: 6px;
    border: 2px solid ${theme.colors.darkPurple};
    border-radius: 2px;
    position: relative;
    flex-shrink: 0;
    background: ${({ checked }) => (checked ? theme.colors.darkPurple : "")};
  }

  .checkmark {
    position: absolute;
    width: 3px;
    height: 7px;
    top: 1px;
    left: 1px;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right-width: 0.15rem;
    border-bottom-width: 0.15rem;
    border-color: white;
    transform-origin: 97% 86%;
    transform: rotate(35deg);
    box-sizing: content-box;
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }
`
