/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { rem } from "@src/theme"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import External from "@components/assets/svg/external.svg"

export default ({
  children,
  bg = "darkPurple",
  color = "white",
  to,
  external = false,
  externalIcon = false,
  styles,
}) =>
  !external ? (
    <Link
      sx={{
        variant: "buttons.primary",
        color,
        bg,
        textDecoration: "none",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          bg: bg === "yellow" ? "darkPurple" : "purple",
          color: "white",
        },
        ...styles,
      }}
      to={to}
    >
      {children}
    </Link>
  ) : (
    <OutboundLink
      sx={{
        variant: "buttons.primary",
        color,
        bg,
        textDecoration: "none",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          bg: bg === "yellow" ? "darkPurple" : "purple",
          color: "white",
        },
        ...styles,
      }}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
      {externalIcon && (
        <External
          width="100%"
          sx={{
            width: rem(13),
            ml: rem(10),
            fill: "currentColor",
            color: "currentColor",
          }}
        />
      )}
    </OutboundLink>
  )
