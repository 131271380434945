import React from 'react'

export default function useGTM() {
    // This is a custom hook that will be used to push events to Google Tag Manager

    const pushEvent = (event) => {
        // This function will push events to Google Tag Manager using window.dataLayer
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push(event)
        }
    }

    return {
        pushEvent
    }
}
