import { client, getNewCheckoutPromise } from "../../helpers"
export const cart = {
  state: {
    adding: false,
    client,
    lineItems: [],
    checkout: null,
    checkoutId: null,
    itemLimits: [],
  },
  reducers: {
    setCheckout(state, { id, ...checkout }) {
      return {
        ...state,
        checkout,
        checkoutId: id,
      }
    },
    setAdding(state, payload) {
      return {
        ...state,
        adding: payload,
      }
    },
    setItemLimits(state, payload) {
      return {
        ...state,
        itemLimits: [...state.itemLimits, ...payload],
      }
    },
    destroyItemLimits(state) {
      return {
        ...state,
        itemLimits: [],
      }
    },
  },
  effects: dispatch => ({
    async refetchCheckout(payload, state) {
      let checkoutId = state.cart.checkoutId
      const checkout = await client.checkout.fetch(checkoutId)

      // Remove items that are no longer available
      if (checkout && checkout.lineItems) {
        const itemsToRemove = checkout.lineItems
          .filter(i => !i.variant.available)
          .map(i => i.id)

        if (itemsToRemove.length) {
          dispatch.cart.removeLineItems(itemsToRemove)
        }
      }

      dispatch.cart.setCheckout(checkout)
    },
    async addVariantToCart(payload, state) {
      const { shopifyId, quantity, productId } = payload
      dispatch.cart.setAdding(true)

      const lineItemsToUpdate = [
        { variantId: shopifyId, quantity: parseInt(quantity, 10) },
      ]

      // Fetch checkout and create new if completed or non-existent
      let checkoutId = state.cart.checkoutId
      try {
        const checkout = await client.checkout.fetch(checkoutId)
        if (checkout.completedAt) {
          throw new Error("Checkout is completed")
        }
      } catch (e) {
        console.log("ERROR: Adding item", e)
        const newCheckout = await getNewCheckoutPromise()
        checkoutId = newCheckout.id
        dispatch.cart.setCheckout(newCheckout)
      }

      // Add item to cart
      client.checkout
        .addLineItems(checkoutId, lineItemsToUpdate)
        .then(res => {
          dispatch.cart.setCheckout(res)
        })
        .catch(error => {
          console.log("CHECKOUT ERROR:", error)
        })
        .finally(() => {
          dispatch.cart.setAdding(false)
        })

      if (!state.cart.itemLimits.find(obj => obj.id === productId)) {
        dispatch.cart.setItemLimits([{ id: productId, limit: payload.limit }])
      }
    },
    async removeLineItem({ id, variantId, productId }, state) {
      const checkoutRes = await client.checkout.removeLineItems(
        state.cart.checkoutId,
        [id]
      )

      dispatch.cart.removeItemLimit({ variantId, productId })

      dispatch.cart.setCheckout(checkoutRes)
    },
    async removeLineItems(ids, state) {
      const res = await client.checkout.removeLineItems(
        state.cart.checkoutId,
        ids
      )
      dispatch.cart.setCheckout(res)
    },
    async resetCheckout(state) {
      const checkout = await getNewCheckoutPromise()
      return {
        ...state,
        checkout: checkout,
        checkoutId: checkout.id,
        lineItems: [],
        adding: false,
        itemLimits: [],
      }
    },
    async resetItemLimits(state) {
      dispatch.cart.destroyItemLimits()
    },
    async removeItemLimit({ variantId, productId }, state) {
      function removeObjectById(id, array) {
        const index = array.findIndex(obj => obj.id === id)
        if (index !== -1) {
          array.splice(index, 1)
        }
        return array
      }

      // check if there are other variants with same id
      const found = state.cart.checkout.lineItems.filter(
        i => i.variant.product.id == productId
      )
      if (!found.length) {
        const itemLimits = removeObjectById(productId, state.cart.itemLimits)
        dispatch.cart.setItemLimits(itemLimits)
      }
    },
    async clearCart(lineItems, state) {
      let res

      try {
        res = await client.checkout.removeLineItems(
          state.cart.checkoutId,
          lineItems
        )
      } catch (e) {
        // If the remove line failed, reset the checkout and try again
        await dispatch.cart.resetCheckout()
        res = await client.checkout.removeLineItems(
          state.cart.checkoutId,
          lineItems
        )
      }
      dispatch.cart.setCheckout(res)
      dispatch.cart.resetItemLimits()
    },
  }),
}
