/** @jsx jsx */
import { jsx } from "theme-ui"
import { Themed } from '@theme-ui/mdx'
import FocusLock from "react-focus-lock"
import { rem } from "@src/theme"
const DonationModal = () => {
  return (
    <div
      sx={{
        pb: [rem(100), null, 0],
      }}
    >
      <div
        sx={{
          bg: "purple",
        }}
      >
        <div
          sx={{
            p: ["5vw", rem(64)],
          }}
        >
          <Themed.h1
            sx={{
              my: 0,
              color: "pink",
            }}
          >
            Every bit counts
          </Themed.h1>
        </div>
      </div>

      <FocusLock
        sx={{
          px: [rem(20), null, rem(116)],
        }}
      >
        <iframe
          className="gn-iframe"
          src="https://www.givenow.com.au/embed/Y2F1c2VpZD01MjYwJmRvbWFpbj1odHRwczovL3ByYWN0aWNhbC10aG9tcHNvbi0xMzRkYzIubmV0bGlmeS5jb20vJnRva2VuPTUyNjA6M2Q1MzM1NGRjNWM4NGFkOQ%3D%3D"
          height="870"
          sx={{
            width: "100%",
          }}
          frameborder="0"
          title="GiveNow donation modal"
        ></iframe>
      </FocusLock>
    </div>
  )
}

export default DonationModal
