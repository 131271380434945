import React from 'react'

export default function useMetaPixel() {
    // This is a custom hook that will be used to push events to Meta Pixel

    const pushEvent = ({eventType = 'track', eventCode}) => {
        // This function will push events to Meta Pixel using window.fbq
        if (typeof window !== 'undefined' && window.fbq) {
            window.fbq(eventType, eventCode)
        }

        if (typeof window !== 'undefined' && window.fbq) {
            window.fbq('track', eventCode)
        }
    }

    return {
        pushEvent
    }
}
