/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { rem } from "@src/theme"
import ArrowRight from "@components/assets/svg/arrow-right.svg"
import ArrowLeft from "@components/assets/svg/arrow-left.svg"
import External from "@components/assets/svg/external.svg"

const IconLink = ({ type, link, label, onClick, hoverColor }) => {
  const Component = !link && onClick ? "a" : Link

  if (type === "arrowRight") {
    return (
      <Component
        sx={{
          variant: "typography.link",
          color: "inherit",
          "&.active": {
            color: "inherit",
          },
          "&:hover": {
            color: hoverColor ? hoverColor : "purple",
          },
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        to={link}
        onClick={onClick}
      >
        <span>{label}</span>
        <ArrowRight
          width="100%"
          sx={{
            width: rem(13),
            transform: "translateX( 8px)",
            fill: "currentColor",
            color: "currentColor",
          }}
        />
      </Component>
    )
  }
  if (type === "external") {
    return (
      <Component
        sx={{
          variant: "typography.link",
          color: "inherit",
          "&.active": {
            color: "inherit",
          },
          "&:hover": {
            color: hoverColor ? hoverColor : "purple",
          },
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        to={link}
        onClick={onClick}
      >
        <span>{label}</span>
        <External
          width="100%"
          sx={{
            width: rem(13),
            transform: "translateX( 8px)",
            fill: "currentColor",
            color: "currentColor",
          }}
        />
      </Component>
    )
  }
  if (type === "arrowLeft") {
    return (
      <Component
        sx={{
          variant: "typography.link",
          color: "inherit",
          "&.active": {
            color: "inherit",
          },
          "&:hover": {
            color: "purple",
          },
          cursor: "pointer",
          textDecoration: "underline",
        }}
        to={link}
        onClick={onClick}
      >
        <ArrowLeft
          width="100%"
          sx={{
            width: rem(13),
            mr: rem(10),
            fill: "currentColor",
            color: "currentColor",
          }}
        />

        <span>{label}</span>
      </Component>
    )
  }
}

export default IconLink
